/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
"use strict";
"use client";

import Alert, { AlertColor, AlertPropsColorOverrides } from "@mui/material/Alert";
import ReactMarkdown, { ExtraProps } from "react-markdown";
import ChallengeBox from "@/components/tasks/custom/widgets/ChallengeBox";
import { TaskConfig, TaskPart } from "@hacksday/libscore";
import { type JSX, type ReactNode } from "react";
import Link from "next/link";
import TaskList from "./TaskList";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { visit } from "unist-util-visit";
import { Root } from "mdast";
import remarkDirective from "remark-directive";
import { OverridableStringUnion } from "@mui/types";
import TaskInstrucion from "./TaskInstruction";
import { Tasks } from "../context/TaskContext";
import ErrorBox from "../general/ErrorBox";
import RawFile from "./RawFile";
import CodeBlock from "./CodeBlock";
import ReviewAppLink from "./custom/widgets/ReviewAppLink";
import ReviewAppList from "./custom/widgets/ReviewAppList";
import CheckService from "./custom/widgets/CheckService";
import Box from "@mui/material/Box";

type MarkdownRenderProps = {
  task?: TaskConfig;
  part?: TaskPart;
  content: string | undefined;
};
export default function MarkdownRender({ part, content, task }: MarkdownRenderProps) {
  const { tasks, scores } = Tasks();

  const MarkdownComponents: object = {
    code(props: JSX.IntrinsicElements["code"] & ExtraProps) {
      const hasLang = /language-(\w+)/.exec(props.className || "");
      // Map to some defaults
      if (hasLang) {
        let lang = hasLang[1] || "plaintext";
        switch (lang) {
          case "text":
            lang = "plaintext";
            break;
          case "sh":
            lang = "bash";
            break;
          case "js":
            lang = "javascript";
            break;
          default:
            break;
        }

        return <CodeBlock language={lang} content={props.children as string} />;
      }
      return <code className={props.className} {...props} />;
    },
    scorecondition(props: JSX.IntrinsicElements["div"]) {
      const sourceTaskId = props["data-sourcetask" as keyof JSX.IntrinsicElements["div"]] as string;
      const maxPoints = props["data-maxpoints" as keyof JSX.IntrinsicElements["div"]] as number;
      const minPoints = props["data-minpoints" as keyof JSX.IntrinsicElements["div"]] as number;
      const sourceTask = tasks?.get(sourceTaskId);
      if (!sourceTask) {
        return (
          <ErrorBox
            title="Unknown Source Task"
            body={`Unknown source task ID ${sourceTaskId}. This is a bug, please talk to a judge`}
          />
        );
      }
      const sourceTaskScore = scores?.task_totals.get(sourceTaskId) || 0;
      if (sourceTaskScore < minPoints) {
        return null;
      }
      if (sourceTaskScore > maxPoints) {
        return null;
      }
      return props.children;
    },
    challenge(props: JSX.IntrinsicElements["div"]) {
      if (part) {
        return <ChallengeBox part={part}>{props.children}</ChallengeBox>;
      }
    },
    alert(props: JSX.IntrinsicElements["div"]) {
      const severity = props["data-severity" as keyof JSX.IntrinsicElements["div"]] as OverridableStringUnion<
        AlertColor,
        AlertPropsColorOverrides
      >;
      return <Alert severity={severity}>{props.children}</Alert>;
    },
    img(props: JSX.IntrinsicElements["img"]) {
      return (
        <Box
          component="img"
          sx={{
            objectFit: "contain",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          src={props.src}
          alt={props.alt}
        />
      );
    },
    collapse(props: JSX.IntrinsicElements["div"]) {
      return (
        <Collapse
          title={(props["data-title" as keyof JSX.IntrinsicElements["div"]] as string) || `Show Code`}
          body={props.children}
        />
      );
    },
    rawfile(props: JSX.IntrinsicElements["div"]) {
      if (task && part) {
        const filename = props["data-file" as keyof JSX.IntrinsicElements["div"]] as string;
        const file = `instructions/${task.id}/${filename}`;

        const type = props["data-type" as keyof JSX.IntrinsicElements["div"]] as string;
        const language = props["data-language" as keyof JSX.IntrinsicElements["div"]] as string;

        return <RawFile part={part} task={task} file={file} type={type} language={language} />;
      }
    },
    loadfile(props: JSX.IntrinsicElements["div"]) {
      if (task && part) {
        const filename = props["data-file" as keyof JSX.IntrinsicElements["div"]] as string;
        const file = `instructions/${task.id}/${filename}`;
        return <TaskInstrucion part={part} task={task} overrideFile={file} />;
      }
    },
    reviewapplink() {
      return <ReviewAppLink />;
    },
    reviewapplist() {
      return <ReviewAppList />;
    },
    checkservice() {
      return <CheckService task={task} />;
    },
    tasklist() {
      return <TaskList />;
    },
    h1(props: JSX.IntrinsicElements["h1"]) {
      return <Typography variant="h1">{props.children}</Typography>;
    },
    h2(props: JSX.IntrinsicElements["h2"]) {
      return <Typography variant="h2">{props.children}</Typography>;
    },
    h3(props: JSX.IntrinsicElements["h3"]) {
      return <Typography variant="h3">{props.children}</Typography>;
    },
    a(props: JSX.IntrinsicElements["a"]) {
      // Open external links in a new tab
      if (props.href?.startsWith("http")) {
        return (
          <Link href={props.href!} target="_blank" rel="noreferrer">
            {props.children}
          </Link>
        );
      }
      return <Link href={props.href!}>{props.children}</Link>;
    },
  };

  function customElements() {
    return (tree: Root): undefined => {
      visit(tree, function (node) {
        if (
          (node.type === "containerDirective" || node.type === "leafDirective" || node.type === "textDirective") &&
          MarkdownComponents[node.name as keyof object]
        ) {
          const attributes = node.attributes || {};
          const data = node.data || (node.data = {});
          data.hName = node.name;
          switch (node.name) {
            case "alert":
              data.hProperties = {
                "data-severity": attributes["severity"] || "info",
              };
              break;
            case "warning":
            case "important":
              data.hProperties = {
                "data-severity": "warning",
              };
              data.hName = "alert";
              break;
            case "info":
              data.hProperties = {
                "data-severity": "info",
              };
              data.hName = "alert";
              break;
            case "scorecondition":
              data.hProperties = {
                "data-sourcetask": attributes["sourcetask"],
                "data-minpoints": attributes["minpoints"],
                "data-maxpoints": attributes["maxpoints"],
              };
              break;
            case "rawfile":
              data.hProperties = {
                "data-file": attributes["file"],
                "data-type": attributes["type"],
                "data-language": attributes["language"],
              };
              break;
            case "loadfile":
              data.hProperties = {
                "data-file": attributes["file"],
              };
              break;
            case "collapse":
              data.hProperties = {
                "data-title": attributes["title"] || "Expand",
              };
              break;
            default:
              return;
          }
        }
      });
    };
  }
  return (
    <ReactMarkdown components={MarkdownComponents} remarkPlugins={[remarkDirective, customElements]}>
      {content}
    </ReactMarkdown>
  );
}

interface YamlProps {
  body: ReactNode;
  title: string;
}
function Collapse({ title, body }: YamlProps) {
  return (
    <Accordion
      slotProps={{
        transition: { unmountOnExit: true },
        heading: { component: "h3" },
      }}
      defaultExpanded={false}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={`${title}-collapse`}
        aria-controls={`${title}-collapse-content`}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>{body}</AccordionDetails>
    </Accordion>
  );
}
