/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
"use client";
"use strict";

import FormGroup from "@mui/material/FormGroup";
import { useEffect, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import useMediaQuery from "@mui/material/useMediaQuery";
import { materialDark, materialLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import SendIcon from "@mui/icons-material/Send";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { getRunUrl, sendMessage } from "@/libs/scoring/tasks/troubleshoot";
import { Tasks } from "@/components/context/TaskContext";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import { TaskConfig } from "@hacksday/libscore";

interface Props {
  task?: TaskConfig;
}

export default function CheckService({ task }: Props) {
  const [serviceUrl, setServiceUrl] = useState<string | undefined>();
  const [serviceError, setServiceError] = useState<string | undefined>();
  const [gemmaQuestion, setGemmaQuestion] = useState<string>("");
  const [gemmaResponse, setGemmaResponse] = useState<string>("");
  const [gemmaLoading, setGemmaLoading] = useState<boolean>(false);

  const isDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const { event } = Tasks();

  useEffect(() => {
    if (!serviceUrl) {
      async function load() {
        try {
          const serviceName = task?.metadata["service_name"] || "gemma-chat-api";
          const url = await getRunUrl(serviceName);
          setServiceUrl(url);
        } catch {
          setServiceError(
            "Could not verify Cloud Run Service URL running in project. Please ask for help as you may not be able to complete this task."
          );
          setServiceUrl("https://YOUR-CLOUD-RUN-URL");
        }
      }
      load();
    }
  }, [serviceUrl, task?.metadata]);

  async function sendChatMessage(e: React.FormEvent) {
    e.preventDefault();
    try {
      setGemmaLoading(true);
      const r = await sendMessage(serviceUrl!, gemmaQuestion);
      if (r.message) {
        setGemmaResponse(r.message);
      }
      if (r.successful) {
        setGemmaQuestion("");
      }
    } finally {
      setGemmaLoading(false);
    }
  }

  return (
    <>
      {serviceError && (
        <>
          <Alert severity="error" sx={{ m: 2 }}>
            {serviceError}
          </Alert>
        </>
      )}
      {!event?.scoring_enabled && (
        <Alert severity="error" sx={{ m: 2 }}>
          <AlertTitle>Scoring Disabled</AlertTitle>
          No more points can be earned during this event until a judge re-activates scoring.
        </Alert>
      )}
      <form onSubmit={sendChatMessage}>
        <FormGroup>
          {(gemmaLoading && <CircularProgress />) || (
            <SyntaxHighlighter
              language="markdown"
              wrapLongLines={true}
              showLineNumbers={false}
              style={isDarkMode ? materialDark : materialLight}
            >
              {gemmaResponse}
            </SyntaxHighlighter>
          )}
          <Stack spacing={1} direction="row" sx={{ mt: 0.1 }}>
            <TextField
              required
              sx={{ width: "100%" }}
              label="Question"
              value={gemmaQuestion}
              disabled={gemmaLoading || !event?.scoring_enabled}
              onChange={(e) => {
                setGemmaQuestion(e.target.value);
              }}
              helperText="e.g. What is a good healthy breakfast?"
            />
            <Tooltip title="Ask Question">
              <Button disabled={gemmaLoading || !event?.scoring_enabled} aria-label="Send Message" type="submit">
                <SendIcon />
              </Button>
            </Tooltip>
          </Stack>
        </FormGroup>
      </form>
      <Accordion sx={{ mt: 3, mb: 2 }} slotProps={{ transition: { unmountOnExit: true } }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="check-service-header"
          aria-controls="check-service-content"
        >
          Alternative: Cloud Shell Verification
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Run the following from Cloud Shell several times to verify if the app is working or not.
          </Typography>
          {(serviceUrl && (
            <>
              <SyntaxHighlighter
                language="bash"
                wrapLongLines={true}
                showLineNumbers={false}
                style={isDarkMode ? materialDark : materialLight}
              >
                {`curl -X POST \\
"${serviceUrl}/generate" \\
-H "Content-Type: application/json" \\
-d '{"prompt":"What is a good healthy breakfast?"}'`}
              </SyntaxHighlighter>
            </>
          )) || <CircularProgress />}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
