"use client";
import { firebaseAuth, storage } from "@/libs/firebase/clientApp";
import { ref, getBlob } from "firebase/storage";
import { useEffect, useState } from "react";
import MarkdownRender from "@/components/tasks/MarkdownRender";
import { TaskConfig, TaskPart } from "@hacksday/libscore";
import { getProjectId } from "@/libs/teams/clientTeam";

import { useAuthState } from "react-firebase-hooks/auth";
import { parseText } from "@/libs/tasks/taskText";

interface TaskInstrucionProps {
  part: TaskPart;
  task: TaskConfig;
  overrideFile?: string;
}

export default function TaskInstrucion({ part, task, overrideFile }: TaskInstrucionProps) {
  const [content, setContent] = useState<string>();
  const [projectId, setProjectId] = useState<string>("YOUR-PROJECT-ID");
  const [user, loading] = useAuthState(firebaseAuth);

  useEffect(() => {
    if (!loading) {
      async function pid() {
        if (user) {
          setProjectId(await getProjectId(user));
        }
      }
      pid();
    }
  }, [user, loading]);

  useEffect(() => {
    async function getContent() {
      const path = overrideFile ? overrideFile : `instructions/${task.id}/${part.instructions_link}`;
      const pr = ref(storage, path);
      const blob = await getBlob(pr);
      const text = parseText({ input: await blob.text(), email: user?.email || "", projectId: projectId });
      setContent(text);
    }
    getContent();
  }, [task, part, projectId, user?.email, overrideFile]);

  return <MarkdownRender part={part} task={task} content={content} />;
}
