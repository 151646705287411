/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { ReactNode } from "react";
import { TaskPart } from "@hacksday/libscore";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

interface ChallengeBoxProps {
  part: TaskPart;
  children: ReactNode;
}

export default function ChallengeBox({ part, children }: ChallengeBoxProps) {
  return (
    <Alert
      severity="success"
      variant="outlined"
      sx={{ m: 2 }}
      icon={<EmojiEventsIcon fontSize="inherit" />}
    >
      <AlertTitle>Challenge {part.max_points}+ Points</AlertTitle>
      {children}
    </Alert>
  );
}
