/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

"use client";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import useMediaQuery from "@mui/material/useMediaQuery";
import { materialDark, materialLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import Box from "@mui/material/Box";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import { Fragment, useState } from "react";

interface Props {
  content: string;
  language: string;
}

export default function CodeBlock({ content, language }: Props) {
  const isDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [snackbar, setSnackbar] = useState(false);

  function copy() {
    setSnackbar(true);
    navigator.clipboard.writeText(content);
  }

  function close(event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar(false);
  }
  const action = (
    <Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={close}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Fragment>
  );

  return (
    <Box sx={{ position: "relative" }}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbar}
        onClose={close}
        message="Text copied to clipboard"
        autoHideDuration={3000}
        action={action}
      />
      <Tooltip title="Copy to clipboard">
        <IconButton
          aria-label="copy"
          onClick={copy}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
            zIndex: 1,
          })}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      <SyntaxHighlighter
        language={language}
        wrapLongLines={true}
        PreTag="div"
        className="codeStyle"
        showLineNumbers={false}
        useInlineStyles={true}
        style={isDarkMode ? materialDark : materialLight}
      >
        {content}
      </SyntaxHighlighter>
    </Box>
  );
}
