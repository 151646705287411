/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

"use client";
import { firebaseAuth, storage } from "@/libs/firebase/clientApp";
import { ref, getBlob } from "firebase/storage";
import { useEffect, useState } from "react";
import { TaskConfig, TaskPart } from "@hacksday/libscore";
import { getProjectId } from "@/libs/teams/clientTeam";

import { useAuthState } from "react-firebase-hooks/auth";
import { parseText } from "@/libs/tasks/taskText";
import CodeBlock from "./CodeBlock";

interface Props {
  part: TaskPart;
  task: TaskConfig;
  file: string;
  type?: string;
  language?: string;
}

export default function RawFile({ part, task, file, type, language }: Props) {
  const [content, setContent] = useState<string>();
  const [projectId, setProjectId] = useState<string>("YOUR-PROJECT-ID");
  const [user, loading] = useAuthState(firebaseAuth);

  useEffect(() => {
    if (!loading) {
      async function pid() {
        if (user) {
          setProjectId(await getProjectId(user));
        }
      }
      pid();
    }
  }, [user, loading]);

  useEffect(() => {
    async function getContent() {
      const pr = ref(storage, file);
      const blob = await getBlob(pr);
      const text = parseText({ input: await blob.text(), email: user?.email || "", projectId: projectId });
      setContent(text);
    }
    getContent();
  }, [task, part, projectId, user?.email, file]);

  if (type === "code" && language && content) {
    return <CodeBlock language={language} content={content} />;
  }

  return <>{content}</>;
}
