/**
 * Copyright 2024 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ErrorBox from "@/components/general/ErrorBox";
import { firebaseAuth, firestore } from "@/libs/firebase/clientApp";
import CircularProgress from "@mui/material/CircularProgress";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

export default function ReviewAppList() {
  const [domain, setDomain] = useState<string>("");
  const [notFound, setNotFound] = useState<boolean>(false);
  const [user, loading] = useAuthState(firebaseAuth);

  useEffect(() => {
    async function updateUrl() {
      if (user && user?.email) {
        const docRef = doc(firestore, "review-app", user?.email);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const url = new URL(docSnap.data().url);
          setDomain(url.hostname);
          setNotFound(false);
        } else {
          setNotFound(true);
        }
      }
    }
    if (!loading) {
      updateUrl();
    }
  }, [loading, user]);

  if (!loading && notFound) {
    return (
      <ErrorBox
        title="Missing Domain"
        body="No Domain found for the review app - please talk to a judge"
      />
    );
  }
  if (!loading && domain === "") {
    <CircularProgress />;
  } else {
    return (
      <ul>
        <li>
          <pre>{domain}</pre>
        </li>
        <li>
          <pre>localhost</pre>
        </li>
      </ul>
    );
  }
}
