"use client";

import Link from "next/link";
import { Tasks } from "../context/TaskContext";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ScoreSchema, TaskConfig } from "@hacksday/libscore";

export default function TaskList() {
  const { scores, tasks } = Tasks();

  const categories: Map<string, string[]> = new Map();
  // Maintain category order based on first-seen
  const catOrder: string[] = [];

  if (tasks) {
    for (const [id, task] of tasks) {
      const group = task.group || "(default)";
      categories.set(group, [...(categories.get(group) || []), id]);
      if (!catOrder.includes(group)) {
        catOrder.push(group);
      }
    }
  }

  return (
    <Box sx={{ width: "100%", maxWidth: "lg" }}>
      {tasks &&
        catOrder.map((category) => {
          const c = categories.get(category);
          return <Category key={category} category={category} categoryTasks={c} scores={scores} tasks={tasks} />;
        })}
    </Box>
  );
}

interface CategoryProps {
  categoryTasks: string[] | undefined;
  tasks: Map<string, TaskConfig>;
  scores: ScoreSchema | undefined;
  category: string;
}

function Category({ categoryTasks, tasks, scores, category }: CategoryProps) {
  if (!categoryTasks) {
    return null;
  }
  return (
    <>
      <Typography variant="h3">{category === "(default)" ? "Tasks" : category}</Typography>
      <ul>
        {categoryTasks.map((id) => {
          const task = tasks.get(id);
          if (!task || task?.hidden) {
            return null;
          }
          return (
            <li key={id}>
              <Typography variant="body1">
                <Link href={`/task/${id}`}>{task.name}</Link> - {task.description || ""} - Score:{" "}
                {scores?.task_totals.get(task.id) || 0} / {task.max_points} (
                {(((scores?.task_totals.get(task.id) || 0) / task.max_points) * 100).toFixed(0)}
                %)
              </Typography>
            </li>
          );
        })}
      </ul>
    </>
  );
}
